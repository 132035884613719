import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { PrismicPreviewProps } from '@prismicio/next'

import BigLink from 'components/BigLink'
import { IconUser } from 'icons'

import { getFullPageByUID } from 'utils/prismic'

const Custom404 = () => {
  const { data } = useSession()
  const { t } = useTranslation('notFound')
  return (
    <>
      <div className="flex h-full flex-col items-center bg-gray-50 py-20 px-10">
        <h1 className="my-2 text-center text-4xl font-bold">{t('title')}</h1>
        <p className="text-center font-medium leading-[115%]">
          {t('description')}
          <br />
          {t('description2')}
        </p>
        <div>
          <BigLink
            hrefDefault={'/sign-up'}
            buttonText={
              <span className="flex items-center gap-2">
                <span className="mr-2 h-6 w-6">
                  <IconUser />
                </span>
                <span>{!data?.user ? t('button') : t('button3')}</span>
              </span>
            }
            className="my-8"
          />
        </div>
      </div>
    </>
  )
}

export async function getStaticProps({
  locale,
  previewData
}: {
  locale: string
  previewData: PrismicPreviewProps
}) {
  const pagePartsPromise = getFullPageByUID(
    'page',
    'notfound',
    { locale },
    { previewData }
  )
  const i18nPromise = serverSideTranslations(locale, [
    'notFound',
    'actions',

    'crm'
  ])

  const [pageParts, i18n] = await Promise.all([pagePartsPromise, i18nPromise])
  const { page, ...layoutParts } = pageParts
  return {
    props: {
      ...i18n,
      page,
      internal: {
        layout: layoutParts
      }
    },
    revalidate: 60 * 60 * 8
  }
}

export default Custom404
